<template>
  <div class="shop">
    <header
      class="shop-header"
      v-if="list.length > 0"
    ><span @click="delAll">删除</span> </header>
    <section class="cart-box">
      <!-- <van-pull-refresh
        v-model="refreshing"
        @refresh="onRefresh"
      >
        <van-list
          v-model="loading"
          :finished="finished"
          :offset='offset'
          finished-text="没有更多了"
          @load="onLoad"
        > -->
      <div class="van-clearfix">
        <div class="cart-body">
          <!-- 多选 -->
          <!-- <van-checkbox-group
            @change="groupChange"
            v-model="result"
            ref="checkboxGroup"
          > -->
          <!--  单选-->
          <!-- <van-radio-group
            v-model="radio"
            @change="redioChange"
          > -->
          <van-swipe-cell
            :right-width="50"
            v-for="(item, index) in list"
            :key="index"
          >
            <div class="good-item">
              <!-- 多选时 -->
              <!-- <van-checkbox :name="item.productId" :checked-color="variables.textColor"></van-checkbox> -->
              <!-- 单选时 -->
              <!-- <van-radio
                  :name="item.productId"
                  :checked-color="variables.textColor"
                /> -->
              <!-- 复选框模拟 单选并且可取消 -->
              <van-checkbox
                :name="item.productId"
                v-model="item.checked"
                :checked-color="variables.textColor"
                @click='onBoxChange(item)'
              />
              <div
                class="good-img"
                @click="onGoodList(item)"
              ><img
                  :src="item.picUrl"
                  alt=""
                ></div>
              <div class="good-desc">
                <div
                  class="good-title"
                  @click="onGoodList(item)"
                >
                  <span>{{ item.goodsName }}</span>
                  <!-- <span>x{{ item.goodsCount }}</span> -->
                </div>
                <p class="goods-desc">{{item.specifications.join(' ')}}</p>
                <div class="good-btn">
                  <div class="price">{{ item.price }}积分</div>
                  <van-stepper
                    integer
                    :min="1"
                    :max="10"
                    v-model="item.number"
                    :name="item.productId"
                    input-width="32px"
                    button-size="22px"
                    @change="onChange"
                  />
                </div>
              </div>
            </div>
            <template #right>
              <van-button
                square
                icon="delete"
                type="danger"
                :color='variables.textColor'
                class="delete-button"
                @click="deleteGood(item.productId)"
              />
            </template>
          </van-swipe-cell>
          <!-- </van-radio-group> -->
          <!-- </van-checkbox-group> -->
        </div>
      </div>
      <!-- </van-list>
      </van-pull-refresh> -->
    </section>
    <van-submit-bar
      v-if="list.length > 0"
      class="submit-all van-hairline--top"
      :price="total*100"
      suffix-label='积分'
      button-text="结算"
      @submit="onSubmit"
    >
      <!-- <van-checkbox
          @click="allCheck"
          :checked-color="variables.textColor"
          v-model="checkAll"
        >全选</van-checkbox> -->
    </van-submit-bar>
    <van-empty
      description="购物车空空如也"
      v-if="list.length === 0"
    >
      <van-button
        round
        type="danger"
        :color='variables.textColor'
        class="bottom-button"
        @click="goMall"
      >逛逛</van-button>
    </van-empty>

    <!-- 选择地址 -->
    <address-popup
      :saveAddressShow.sync='saveAddressShow'
      :addressList='addressList'
      :checkGoodls='checkGoodls'
      :routerType='routerType'
      @onSaveDetail='onSaveDetail'
      @onSave='onSave'
      @onDelete='onDelete'
      @onAddressSubmit='onAddressSubmit'
      @onAddressSubmitPop='onAddressSubmitPop'
    ></address-popup>
    <!-- //立即兑换 -->
    <points-pop
      :integralPopup.sync='integralPopup'
      :retailPrice='userProint'
      :goodId='goodId'
      @onExSubmit='onExSubmit'
      @orderSubmitPop='orderSubmitPop'
    ></points-pop>
    <!-- 登录 -->
    <overlay-view
      :overlayShow.sync='overlayShow'
      @login='login'
    ></overlay-view>
    <!-- //登录时加载状态 -->
    <van-overlay
      :show="isCurLOgin"
      class-name='loginOverlay'
    >
      <div class="wrapper">
        <div
          class=""
          style="text-align:center"
        >
          <van-loading
            size="30px"
            color="#fff"
          >登录中...</van-loading>
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import API from "../../api/http";
import { Dialog, Toast } from "vant";
import variables from "@/styles/variables.scss";
import areaList from "@/utils/area";
import AddressPopup from "@/components/address-popup";
import PointsPop from "@/components/points-pop";
import cast from "@/utils/cast";
import OverlayView from "../../components/overlay-view";
export default {
  name: "shop",
  components: { AddressPopup, PointsPop, OverlayView },
  data() {
    return {
      areaList,
      checkAll: false,
      result: [],
      list: [],
      listQuery: {
        page: 1,
      },
      total: 0, //总积分值
      userProint: 0, //当前选中商品积分值
      goodId: "", //d当前商品id
      radio: "", //当前选中产品id
      // username: "", //姓名
      // usertel: "", //电话
      // uservalue: "", //地址信息
      // userdetail: "", //地址详情
      // sendPopup: false,
      // showArea: false,
      // phoneCode: "",
      // timer: "",
      // count: "发送验证码",
      loading: false,
      finished: false,
      refreshing: false,
      offset: 60,
      // chosenAddressId: "", //地址id
      // city: "", //市
      // county: "", //区
      // province: "", //省
      // areaCode: "", //省市区code码
      checkedAddress: "", //当前填的地址信息
      saveAddressShow: false,
      addressList: [],
      checkGoodls: {},
      chosenAddressId: "",
      integralPopup: false, //立即购买显示
      routerType: "shop",
      overlayShow: false,
      isCurLOgin: false,
    };
  },
  computed: {
    variables() {
      return variables;
    },
    userType() {
      return this.$store.getters["user/userType"];
    },
    phone() {
      return this.$store.state.user.phone;
    },
    isLogin() {
      return this.$store.getters["user/isLogin"];
    },
  },
  created() {
    console.log("kk");
    if (this.isLogin) {
      this.getInit();
    }
  },
  methods: {
    onLoad() {
      if (this.refreshing) {
        this.list = [];
        this.refreshing = false;
      }
      console.log("onLoad");
      this.loading = true;
      this.getInit();
    },
    getInit() {
      //购物车列表没有分页
      API.getCartList().then((res) => {
        let result = res.data;
        if (result.cartList.length > 0) {
          let list = result.cartList.map((item) => {
            if (item.checked) {
              this.radio = item.productId;
              this.total = item.price * item.number;
              this.goodId = item.id;
            }
            return {
              ...item,
            };
          });

          this.list = list;
        }
      });
    },
    onRefresh() {
      //下拉刷星
      // 清空列表数据

      // this.finished = false;

      // // 重新加载数据
      // // 将 loading 设置为 true，表示处于加载状态
      // this.loading = true;
      // this.listQuery.page = 1;
      this.radio = "";
      this.goodId = "";
      this.total = 0;
      this.list = [];
      this.getInit();
    },
    onChange(value, detail) {
      console.log("kk", value, detail);
      let map = "";
      for (let item of this.list) {
        if (item.productId === detail.name) {
          map = item;
          break;
        }
      }
      this.total = map.number * map.price;
      //修改数量
      API.updateCardNum({
        goodsId: map.goodsId,
        productId: map.productId,
        number: map.number,
        id: map.id,
      }).then((res) => {});
    },
    onGoodList(item) {
      this.$router.push({
        path: "/commodity",
        query: {
          id: item.goodsId,
        },
      });
    },
    deleteGood(id) {
      //单个删除
      Dialog.confirm({
        title: "提示",
        message: "是否确定删除?",
      })
        .then(() => {
          // on confirm
          // this.list = this.list.filter((item) => item.productId !== id);
          // this.result = this.result.filter((item) => item !== productId);
          API.delCard({ productIds: [id] }).then((res) => {
            Toast("删除成功");
            this.onRefresh();
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    delAll() {
      if (this.radio === "") {
        Toast("请选择需要删除的商品");
        return;
      }

      this.deleteGood(this.radio);

      // 批量删除
      // if (this.result.length === 0) {
      //   return;
      // }
      // Dialog.confirm({
      //   title: "提示",
      //   message: "是否确定删除?",
      // })
      //   .then(() => {
      //     // on confirm
      //     this.list = this.list.filter(
      //       (item) => !this.result.includes(item.id)
      //     );
      //     this.result = [];
      //   })
      //   .catch(() => {
      //     // on cancel
      //   });
    },
    allCheck() {
      //全选ue

      if (this.checkAll) {
        this.result = this.list.map((item) => item.productId);
        this.setTotal();
      } else {
        this.result = [];
      }
    },
    setTotal() {
      this.total = 0;
      let list = this.list;

      for (let item of list) {
        if (item.productId === this.radio) {
          this.total += item.price * item.number;
          this.goodId = item.id;
          break;
        }
      }

      API.checkCard({ productIds: [this.radio], isChecked: 1 }).then(
        (res) => {}
      );
    },
    groupChange(val) {
      //多选

      if (this.result.length == this.list.length) {
        this.checkAll = true;
      } else {
        this.checkAll = false;
      }
      this.setTotal(this.result);
    },
    onBoxChange(item) {
      //复选框 模拟单选
      this.radio = "";

      this.list.forEach((v) => {
        if (v.productId === item.productId && item.checked) {
          this.radio = v.productId;
          v.checked = true;
        } else {
          v.checked = false;
        }
      });

      this.setTotal();
      console.log("kkk", this.radio);
    },
    redioChange(val) {
      //单选
      console.log("redioChange", val);
      this.setTotal();
    },
    login(data) {
      this.getLogin2({ ...data, mobileType: 1 });
    },
    getLogin2(data) {
      this.isCurLOgin = true;
      this.$store
        .dispatch("user/shopLogin", data)
        .then((res) => {
          Toast.clear();
          this.onSubmit();
          this.overlayShow = false;
          this.isCurLOgin = false;
        })
        .catch((err) => {
          this.isCurLOgin = false;
          Toast(err.errmsg);
        });
    },
    onSubmit() {
      //结算

      if (this.radio === "") {
        Toast("请选择需要结算的商品");
        return;
      }
      if (this.phone === "") {
        this.overlayShow = true;
        return;
      }
      this.checkGoodls = {
        goodsId: "",
        productId: this.radio,
        number: 0,
        id: "",
      };
      for (let item of this.list) {
        if (item.productId === this.radio) {
          this.userProint = item.price;
          this.checkGoodls.goodsId = item.goodsId;
          this.checkGoodls.number = item.number;
          this.checkGoodls.id = item.id;
          break;
        }
      }
      // this.integralPopup = true;
      // this.getAddressList(1);

      //普通用户有地址则先选择地址，然后获取验证码兑换，没有则出现填写地址兑换流程
      //b端用户，有无地址都出现填写地址兑换流程

      if (this.userType === cast.USER_LEVEL0) {
        //普通用户
        this.getAddressList(1);
      } else {
        //b端
        this.integralPopup = true;
      }
    },
    goMall() {
      this.$router.push("/mall");
      this.$store.commit("menu/bottomId", 1);
    },
    getSendPaySms() {
      // 验证手机号
      if (!this.username) {
        Toast("请输入收货人姓名");
        return;
      }
      if (!this.usertel) {
        Toast("请输入收货人电话");
        return;
      }
      if (!this.uservalue) {
        Toast("请输入地址");
        return;
      }
      if (!this.userdetail) {
        Toast("请输入详细地址");
        return;
      }
      const TIME_COUNT = 60; // 更改倒计时时间

      if (!this.timer) {
        // 发送验证码
        Toast.loading({
          message: "等待中...",
          forbidClick: true,
          duration: 0,
        });

        const obj = {
          addrid: this.chosenAddressId,
        };
        // API.postSendPaySms(obj).then((res) => {
        //   if (response.code === "200") {
        //     this.sendPaySmsOrder = response.data;
        //     Toast("发送成功");
        //     this.sendPopup = true;
        //   }
        //   Toast.clear();
        // });

        this.count = TIME_COUNT;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            clearInterval(this.timer); // 清除定时器
            this.count = "未收到，重新发送";
            this.timer = null;
          }
        }, 1000);
      }
    },
    getAddressList(val) {
      API.getAddressList().then((res) => {
        let list = res.data.list;
        this.addressList = list.map((v) => {
          return {
            ...v,
            address: v.province + v.city + v.county,
          };
        });
        if (val) {
          if (list.length > 0) {
            this.saveAddressShow = true;
          } else {
            this.integralPopup = true;
          }
        }
      });
    },
    onAddressSubmit() {
      //地址选择弹框的确定兑换
      this.onRefresh();
    },
    onSaveDetail(item) {
      //新增地址的保存

      this.getAddressList();
    },
    onSave() {
      //编辑地址的保存

      this.getAddressList();
    },
    onDelete() {
      this.getAddressList();
    },
    async onExSubmit() {
      //确认兑换
      setTimeout(() => {
        this.integralPopup = false;
        this.onRefresh();
      }, 1000);

      // await this.addAddress(data);
      // await this.checkoutCart();
      // await this.orderSubmit();
    },

    addAddress(data) {
      //没有地址时，添加的地址调用 地址添加接口，以获取地址id
      const obj = {
        id: 0,
        city: data.city,
        county: data.county,
        province: data.province,
        name: data.name,
        tel: data.tel,
        addressDetail: data.addressDetail,
        isDefault: false,
        areaCode: data.areaCode,
      };
      return API.postAddressAdd(obj).then((res) => {
        this.chosenAddressId = res.data;
      });
    },
    checkoutCart() {
      API.checkoutCart({
        cartId: this.goodId,
        addressId: this.chosenAddressId,
      }).then((res) => {
        this.checkedAddress = res.data.checkedAddress;
      });
    },
    orderSubmit() {
      return API.orderSubmit({
        cartId: this.goodId,
        addressId: this.chosenAddressId,
      }).then((res) => {
        Toast("兑换成功");
        this.integralPopup = false;

        this.onRefresh();
      });
    },
    orderSubmitPop() {
      this.onRefresh();
    },
    onAddressSubmitPop() {
      this.onRefresh();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/mixin.scss";
.shop-header {
  padding: 10px 10px 0 10px;
  text-align: right;
  & > span {
    cursor: pointer;
  }
}
.cart-box {
  padding: 10px;
  .van-swipe-cell .van-swipe-cell__right {
    right: -1px;
  }
  .cart-body {
    margin: 0px 0 100px 0;
    background: #fff;
    border-radius: 10px;
    .good-item {
      display: flex;
      padding: 20px 6px;
      position: relative;
      &::after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        width: 90%;
        margin: auto;
        border-top: 1px solid #f4f4f4;
      }
      // border: 1px solid #f4f4f4;
      .good-img {
        img {
          @include wh(100px, 100px);
        }
      }
      .good-desc {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;

        // padding: 20px 0;
        .good-title {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
          font-size: 14px;
        }
        .goods-desc {
          font-size: 12px;
          color: #999;
        }
        .good-btn {
          display: flex;
          justify-content: space-between;
          .price {
            font-size: 16px;
            color: $textColor;
            line-height: 28px;
          }
          .van-icon-delete {
            font-size: 20px;
            margin-top: 4px;
          }
        }
      }
    }
    .delete-button {
      width: 50px;
      height: 100%;
    }
  }
  .empty {
    width: 50%;
    margin: 0 auto;
    text-align: center;
    margin-top: 200px;
    .empty-cart {
      width: 150px;
      margin-bottom: 20px;
    }
    .van-icon-smile-o {
      font-size: 50px;
    }
    .title {
      font-size: 16px;
      margin-bottom: 20px;
    }
  }
}
.submit-all {
  margin-bottom: 50px;
  .van-submit-bar__price,
  .van-submit-bar__suffix-label {
    color: $textColor;
  }
  .van-submit-bar__text {
    margin-right: 10px;
  }
  .van-submit-bar__button {
    background: $background-color;
  }
}
.van-checkbox__icon--checked .van-icon {
  background-color: $background-color;
  border-color: $background-color;
}
</style>
